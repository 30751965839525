import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "account";

export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  edit(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  getUserById(id) {
    return Api().get(baseUrl + `/${id}/Profile`);
  },
  suspend(id, value) {
    return Api().put(baseUrl + `/${id}/${value}`);
  },
  roles() {
    return Api().get(baseUrl + `/Roles`);
  },
  updateRoles(id, roles) {
    return Api().put(baseUrl + `/${id}/roles`, { roles: roles });
  },
  updateSuperUser(id, isSuperUser) {
    return Api().patch(baseUrl + `/${id}/super-user`, { isSuperUser: isSuperUser });
  },
  updatePassword(id, form) {
    return Api().put(baseUrl + `/${id}/password`, form);
  },
  download(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "/download?" + qParams.toString());
  },
  exportExcel(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "/Export?" + qParams.toString());
  },
  exportLaborRatesExcel(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "/ExportLaborRates?" + qParams.toString());
  },
  msLogin(response) {
    var body = {
      accessToken: response.accessToken,
      userName: response.account.username,
    };
    return Api().post(baseUrl + `/Login/Microsoft`, body);
  },
};
